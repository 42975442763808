import React, { useContext } from 'react';

import Collections from '@components/Collections';
import LobbyLayout from '@components/LobbyLayout';
import StyledRouter from '@components/StyledRouter';
import UserContext from '@contexts/UserContext';
import { navigate } from 'gatsby';

const withLobbyLayout = (Component) => (props) => {
  return (
    <LobbyLayout>
      <Component {...props} />
    </LobbyLayout>
  );
};
const CollectionsLayout = withLobbyLayout(Collections);

const ConnectWallet = () => {
  const { connected } = useContext(UserContext);
  if (connected) {
    navigate('/collections')
  }
  return "Please connect your wallet"
}
const ConnectWalletLayout = withLobbyLayout(ConnectWallet);

const IndexPage = () => {
  return (
    <StyledRouter basepath="/">
      <CollectionsLayout path="/collections" />
      <ConnectWalletLayout path="/" />
    </StyledRouter>
  );
};

export default IndexPage;
